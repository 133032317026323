.detail {
  display: flex;
  gap: 10px;
  padding: 10px;
  border: 1px solid black;
  border-radius: 10px;
  margin-top: 2px;
  align-items: center;
  justify-content: start;

  .header {
    font-weight: bold;
    width: 150px;
  }
  .desc {
    font-weight: 500;
  }
}
