.home{

    h1{
        font-size: 20px;
        font-weight: bold;
    }

    p{
        font-size: 14px;
        color: gray;
    }
}